.v-container .image-container {
  border: 10px solid rgb(15, 14, 14);
} 
.v-container > div {
    flex: 1;
  }
  .v-right {
    gap: 0.5rem;
  }

  .accordion {
    margin-top: 2rem;
    border: none;
  }
  .accordionItem {
    background: white;
    border: 1px solid black;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
  }

  .accordionButton {
    background: white;
    padding: 1rem;
    width: 100%;
    justify-content: space-between;
    cursor: pointer;
  }

  .icon {
    padding: 10px;
    background-color: aliceblue;
    border-radius: 5px;
  }

  .icon svg {
    fill: blue;
  }
  
  .accordionButton .primaryText {
    font-size: 1.4rem;
  }
  
  .accordionButton {
    box-shadow: var(--shadow);
    border-radius: 6px;
  }
  
  @media (max-width: 1024px) {
    .v-container {
      flex-direction: column;
    }
  }
  
  @media (max-width: 768px) {
    .accordionButton .primaryText {
      font-size: 0.8rem;
    }
  }